import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from 'styled-components'

const StyledDiv = styled.div`
  height: 233px;

  @media (max-width: 767px) {
    height: 45px;
  }

  @media (max-width: 1024px) {
    height: 96px;
  }

  @media (max-width: 1280px) {
    height: 145px;
  }
`

const StyledSliderCustom = styled(Slider)`
  .slick-next {
    display: none !important;
  }
  
`

const HomeBannerSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query HomeBannerSectionUpdateQuery {
      prismicHomePage {
        data {
          banners {
            banner_image {
              alt
              gatsbyImageData(
                width: 1920
                height: 240
                layout: FULL_WIDTH
                placeholder: NONE
              )
            }
            banner_link
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePage
  const banners = doc.data.banners

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    lazyLoad: true,
    pauseOnHover: true,
  }

  return (    
    <StyledDiv className="w-full bg-site-green-light mx-auto">
      <StyledSliderCustom {...settings}>
        {banners.map((banner) => {
          const imgData = getImage(banner.banner_image)
          return (
            <div>
              {banner.banner_link
                ?
                <a href={banner.banner_link} target="_blank" rel="noopener noreferrer">
                  <GatsbyImage
                    image={imgData}
                    alt={banner.banner_image.alt ? banner.banner_image.alt : "banner image"}
                    loading="eager"
                  />
                </a>
                :
                <GatsbyImage
                  image={imgData}
                  alt={banner.banner_image.alt ? banner.banner_image.alt : "banner image"}
                  loading="eager"
                />
              }
            </div>
          )
        })}
      </StyledSliderCustom>
    </StyledDiv>
  )
}

export default HomeBannerSectionUpdate