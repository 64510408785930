import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { navigate } from '@reach/router'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const HeroHome = ({ agentPage }) => {
  const data = useStaticQuery(graphql`
    query HeroHomeQuery {
      prismicHomePage {
        data {
          hero_title
          hero_subtitle
          button_name_1
          button_name_2
          hero_title_agent
          hero_subtitle_agent
          hero_logo {
            gatsbyImageData(
              width: 307
            )
          }
          hero_background_image {
            gatsbyImageData(
              width: 1920
            )
          }
        }
      }
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const doc = data.prismicHomePage
  const heroLogo = getImage(doc.data.hero_logo)
  const heroBgImage = getImage(doc.data.hero_background_image)
  const heroBgPlaceholder = getImage(data.imgDefault)

  return (
    <div className="relative" style={{ display: "grid", background: "#423E42" }}>
      <GatsbyImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.7",
          height: 800,
        }}
        alt="hero image"
        image={heroBgImage ? heroBgImage : heroBgPlaceholder}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 -mt-12">
          <div className="flex justify-center">
            <GatsbyImage
              image={heroLogo ? heroLogo : null}
              alt="logo"
              className="w-2/3 md:w-full mx-auto"
            />
          </div>
          <h2 className="text-white text-5xl md:text-6xl font-extrabold text-center uppercase mt-4">
            {agentPage
              ?
              doc.data.hero_title_agent
              :
              doc.data.hero_title
            }
          </h2>
          <h3 className="max-w-xl text-white text-xl md:text-2xl font-bold text-center mt-6">
            {agentPage
              ?
              doc.data.hero_subtitle_agent
              :
              doc.data.hero_subtitle
            }
          </h3>
          <div className="flex flex-col md:flex-row items-center justify-center mt-12 md:space-x-6 space-y-6 md:space-y-0">
            <button
              onClick={() => {navigate('#sra-plans')}}
              className="w-full md:w-72 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3"
            >
              {doc.data.button_name_1 ? doc.data.button_name_1 : "Button Name"}
            </button>
            <Link to="/contact">
              <button className="w-full md:w-72 bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-16 py-3">
                {doc.data.button_name_2 ? doc.data.button_name_2 : "Button Name"}
              </button>
            </Link>
          </div>
          <div className="absolute bottom-0 mb-12 flex justify-center">
            <StaticImage
              src="../../images/sections/home-hero-arrow-down.png"
              width={98}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="arrow down"
              className="w-2/3 md:w-full mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

HeroHome.propTypes = {
  agentPage: PropTypes.bool,
}

export default HeroHome
