import React from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
`

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
`

const StyledDiv2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden;
`

const StyledDiv3 = styled.div`
  background-color: rgba(255,255,255,0.7);
`

const pStyle = "text-base md:text-xl text-center mb-6"

const SectionMission = ({ agentPage }) => {
  const VidSource = 'https://cdn2.831b.com/Marketing-Site/mission-video-bg.mp4'

  const data = useStaticQuery(graphql`
    query SectionMissionQuery {
      prismicHomePage {
        data {
          mission_section_title
          mission_section_text {
            richText
          }
          mission_section_text_agent {
            richText
          }
          mission_section_button_text
          mission_section_button_link {
            link_type
            uid
            url
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePage

  return (
    <StyledDiv className='hidden xl:flex xl:items-center xl:justify-center z-0 bg-gray-200'>
      <StyledVideo autoPlay='autoplay' loop='loop' muted>
        <source src={VidSource} type='video/mp4' />
        Your browser does not support the video tag.
      </StyledVideo>

      <StyledDiv2>
        <StyledDiv3 className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center mx-auto py-12 mt-10 px-8 rounded-3xl shadow-md z-10">
          <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto">
            <h2 className="text-white text-base md:text-2xl uppercase font-bold text-center">{doc.data.mission_section_title ? doc.data.mission_section_title : "Section Title"}</h2>
          </div>

          <div className="w-full max-w-2xl mt-12 text-center">
            {agentPage
              ?
              doc.data.mission_section_text_agent.richText
                ?
                <RichText render={doc.data.mission_section_text_agent.richText} htmlSerializer={htmlSerializer} />
                :
                <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              :
              doc.data.mission_section_text.richText
                ?
                <RichText render={doc.data.mission_section_text.richText} htmlSerializer={htmlSerializer} />
                :
                <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            }
          </div>

          <div className="flex flex-col md:flex-row items-center mt-6 md:space-x-6 space-y-6 md:space-y-0">
            {doc.data.mission_section_button_link.link_type === "Document" || doc.data.mission_section_button_link.link_type === "Any"
              ?
              <Link to={doc.data.mission_section_button_link.uid ? doc.data.mission_section_button_link.uid : "/"}>
                <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">{doc.data.mission_section_button_text ? doc.data.mission_section_button_text : "Button Name"}</button>
              </Link>
              :
              doc.data.mission_section_button_link.link_type === "Web" || doc.data.mission_section_button_link.link_type === "Media"
              ?
              <a
                href={doc.data.mission_section_button_link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">{doc.data.mission_section_button_text ? doc.data.mission_section_button_text : "Button Name"}</button>
              </a>
              :
              null
            }
          </div>
        </StyledDiv3>
      </StyledDiv2>
    </StyledDiv>
  )
}

SectionMission.propTypes = {
  agentPage: PropTypes.bool,
}

export default SectionMission