import * as React from "react"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import HeroHome from "../components/sections/hero-home"
import IntroHomeNew from "../components/sections/intro-home-new"
import SectionMission from "../components/sections/section-mission"
import PlansSection from "../components/sections/plans-section"
import HomeBannerSectionUpdate from "../components/sections/home-banner-section-update"

const HomeOldPage = () => (
  <LayoutUpdate>
    <Seo
      title="SRA 831(b) for Business Owners"
      description="SRA has made it possible for any successful business to take advantage of the same tools previously reserved for Fortune 500 Companies and utilize an 831(b) Plan to cover uncommon, underinsured or uninsured risks with tax-deferred funds."
    />

    <HeroHome />
    <HomeBannerSectionUpdate />
    <IntroHomeNew />
    <SectionMission />
    <PlansSection />
  </LayoutUpdate>
)

export default HomeOldPage
